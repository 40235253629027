@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

:root{
  --GREY: #D0CFC8;
  --LIGHT_GREY: #E6E7EA;
  --BLUE1: #4B11AD;
  --BLUE2: #117FAD;
  --BLUE3: #16AAE8;
  --BLUE4: #640CFA;
  --YELLOW: #FABB00;
  --BLACK: #141624;
  ;
}

::-webkit-scrollbar {
  width: 0px;
}

#root, html, body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--BLACK);
  color: white;
}

.page{
  position: relative;
  min-height: 100vh;
}

.subtitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--GREY);
  }
  
.grey{
  color: var(--GREY);
}



/***********************************************************************/
/******************************  MOBILE   ******************************/
/***********************************************************************/

@media only screen and (max-width: 1199px){

  .only-full {
    display:none;
  }

  .title{
    font-size: 32px;
    font-weight: 700;
  }

  .smalltitle{
    font-size: 32px;
    font-weight: 700;
  }

  .text{
    font-weight: 400;
  }

  .q{
    font-family: 'Poppins', sans-serif;
    color: var(--YELLOW);
    font-weight: 700;
    font-size: 64px;
  }

}

/***********************************************************************/
/******************************  DESKTOP  ******************************/
/***********************************************************************/

@media only screen and (min-width: 1200px){

  .only-reduce {
    display:none;
  }

  .title{
    font-size: 60px;
    font-weight: 700;
  }

  .smalltitle{
    font-size: 48px;
    font-weight: 700;
  }

  .text{
    font-size: 28px;
    font-weight: 400;
  }

  .q{
    font-family: 'Poppins', sans-serif;
    color: var(--YELLOW);
    font-weight: 700;
    font-size: 128px;
  }
  

}

/***********************************************************************/
/******************************  TYPO    *******************************/
/***********************************************************************/





.footer{
  padding: 2vh;
  background-color: var(--BLACK);
  color: white;
}

.footer a{ color:white }
.footer a:hover{ color:#CCC; text-decoration:none }
.footer li a:hover{ color:#CCC; text-decoration:none }

/***********************************************************************/
/******************************  ROADMAP  ******************************/
/***********************************************************************/

#roadmap-header{
  height: 32vh;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: linear-gradient(180deg, var(--BLACK) 0%, var(--BLUE2) 100%);
  opacity: 25%;
}

.roadmap-pp{
  position: absolute;
  padding: 3%;
  left: 62vw;
  top: 12vh;
  width: 20vw;
  background-color: var(--BLACK);
}

#roadmap-title{
  position:absolute;
  background-color: transparent;
  top: 20vh;
  left: 20vw;
}


.roadmap-button{
  background-color: #9F9F9F;
  border: 1px solid var(--BLACK);
  color: var(--BLACK);
  font-weight: 600;
  font-size: 16px;
  height: 6vh;
  width: 8vw;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  vertical-align: top;
}

.focus {
  background: var(--YELLOW);
  font-weight: 700;
  font-size: 24px;
  width: 10vw;
  height: 9vh;
}

/***********************************************************************/
/******************************  FRAME   *******************************/
/***********************************************************************/

.frame{
  width: 100vw;
  margin-top: 5vh;
  height: auto;
  background-color: var(--BLACK);
}

.frame-line{
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #4B11AD 0%, #E0A800 47.92%, #4B11AD 100%);
}

.frame-img{
  width: 8vw;
  height: auto;
  margin-inline: 2vw;
}
 
.frame-box {
  overflow: hidden;
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  background: linear-gradient(90deg, #4B11AD -1.65%, #E0A800 51.72%, #117FAD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  white-space: nowrap;
}

.frame-box span {
  animation: defilement 20s infinite linear;
}


@keyframes defilement {
  0% { margin-left: 0; }
  100% { margin-left: -100%; }
}

/***********************************************************************/
/******************************  FAQ     *******************************/
/***********************************************************************/

.accordion{
  background-color: var(--BLACK) !important;
  margin-top: 10vh;
}

.accordion-item{
  position: relative;
  margin: 2vw;
  background-color: var(--BLACK) !important;
  border-radius: 40px !important;
  border: 3px solid rgba(75, 17, 173, 0.4) !important;
}

.accordion-button{
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  color: white !important;
  padding: 4% !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  background: radial-gradient(50% 133.22% at 100% 100%, rgba(100, 12, 250, 0.15) 0%, rgba(100, 12, 250, 0) 100%), radial-gradient(50.08% 79.2% at 0% 100%, rgba(250, 187, 0, 0.15) 0%, rgba(250, 187, 0, 0) 100%), #141624 !important; 
}

.accordion-body{
  padding-top: 3% !important;
  text-align: left;
  color: white !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: radial-gradient(50% 133.22% at 100% 100%, rgba(100, 12, 250, 0.15) 0%, rgba(100, 12, 250, 0) 100%), radial-gradient(50.08% 79.2% at 0% 100%, rgba(250, 187, 0, 0.15) 0%, rgba(250, 187, 0, 0) 100%), #141624;
}

.accordion-button.collapsed {
  background: radial-gradient(50% 133.22% at 100% 100%, rgba(100, 12, 250, 0.15) 0%, rgba(100, 12, 250, 0) 100%), radial-gradient(50.08% 79.2% at 0% 100%, rgba(250, 187, 0, 0.15) 0%, rgba(250, 187, 0, 0) 100%), #141624 !important;
  border-radius: 40px !important;
}

.faq-arrow{
  position: absolute;
  right: 2%;
  z-index: 1;
}


/***********************************************************************/
/************************ COMMUNITY      *******************************/
/***********************************************************************/

.community{
  margin-top: 20vh;
  height: 50vh;
  background: linear-gradient(99.19deg, #000738 -0.03%, #9CA6ED 125.77%);
  border-radius: 30px;
}

/***********************************************************************/
/************************ FOOTER         *******************************/
/***********************************************************************/

.footer{
  text-decoration: none;
}

.footer:hover{
  color: var(--BLUE3);
}

/***********************************************************************/
/******************************  NEONS   *******************************/
/***********************************************************************/

#neon-yellow {
  position: absolute;
  left: 20%;
  right: 50%;
  top: 0%;
  bottom: 90%;
  background: var(--YELLOW);
  filter: blur(150px);
}
  
#neon-blue{
  position: absolute;
  left: 0%;
  right: 79%;
  top: 17%;
  bottom: 39%;
  background: var(--BLUE1);
  filter: blur(250px);
  opacity: 50%;
}

#neon-lightblue{
  position: absolute;
  left: 84%;
  right: 0%;
  top: 28%;
  bottom: 27%;
  background: var(--BLUE2);
  filter: blur(250px);
  opacity: 50%;
}

#roadmap-ellipse{
  position: absolute;
  left: 90%;
  right: 0%;
  top: 60%;
  bottom: 0%;
  background: #4B11AD;
  filter: blur(150px);
}

#roadmap-ellipse2{
  position: absolute;
  left: 11%;
  right: 58%;
  top: 80%;
  bottom: 5%;
  background: #16AAE8;
  filter: blur(150px);
  opacity: 25%;
}

#team-1{
  position: absolute;
  left: 30%;
  right: 30%;
  top: 5%;
  bottom: 75%;
  background: #FABB00;
  filter: blur(150px);
  opacity: 75%;
}

#team-2{
  position: absolute;
  right: 70%;
  bottom: 40%;
  left: 0%;
  top: 0%;
  background: #4B11AD;
  filter: blur(250px);
  opacity: 50%;
}

#team-3{
  position: absolute;
  right: 0%;
  bottom: 40%;
  left: 70%;
  top: 0%;
  background: #4B11AD;
  filter: blur(250px);
  opacity: 50%;
}

